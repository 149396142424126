import React, { useState, useEffect } from "react";
import { Images } from "../../../assets/asset";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { getPayment } from "../../stripe-payment/stripe-payment.action";
import MainMenu from "./main-menu";
import SideFoot from "./side-footer";
import "./sidebar.css";
const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const currentPathname = location.pathname;
  const accessToken = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : "";
  const [show, setShow] = useState(false);
  const [showSide, setShowSide] = useState(false);
  const updateSideBarAuth = useSelector(
    (state) => state.payments?.updateSideBarAuth
  );
  const resultVideoAuth = useSelector(
    (state) => state.videoForm?.resultVideoAuth
  );
  const cancelSubscriptionAuth = useSelector(
    (state) => state.payments?.cancelSubscriptionAuth
  );
  useEffect(() => {
    dispatch(getPayment());
  }, [updateSideBarAuth, resultVideoAuth, cancelSubscriptionAuth]);
  useEffect(() => {
    if (!accessToken) {
      navigate("/login");
    }
  }, [accessToken]);
  const showMenu = () => {
    setShow(!show);
  };
  const showSidebar = () => {
    setShowSide(!showSide);
  };
  return (
    <div className={`studio-sidebar d-flex flex-column justify-content-between align-items-start ${showSide ? "show" : ""}`}>
      <span className="bars" onClick={showSidebar}>
        <FontAwesomeIcon icon={faBars} />
      </span>
      <div className="logo">
        <Link to="/studio" className="d-inline-block">
          <img className="img-fluid" alt="" src={Images.whiteLogo} />
        </Link>
      </div>
      <MainMenu currentPathname={currentPathname} />
      <SideFoot showMenu={showMenu} show={show} accessToken={accessToken} />
    </div>
  );
};

export default Sidebar;
