const ErrorMiddleware = (interceptors) => (store) => (next) => (action) => {
  if (action?.payload?.user404) {
    localStorage.clear();
    window.location.href = "/login";
  }
  next(action);
};

const interceptors = [{ type: "404Error", handler: () => {} }];

export const errorMiddleware = ErrorMiddleware(interceptors);
