// ERRORS
export const EMPTY_ERRORS = "EMPTY_ERRORS";
export const GET_ERRORS = "GET_ERRORS";

// USERS
export const BEFORE_USER = "BEFORE_USER";
export const SET_USER = "SET_USER";
export const SIGNUP_USER = "SIGNUP_USER";
export const LOGIN_USER = "LOGIN_USER";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const VERIFY_USER = "VERIFY_USER";
export const GOOGLE_SOCIAL = "GOOGLE_SOCIAL";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";

// AVATAR
export const BEFORE_AVATAR = "BEFORE_AVATAR";
export const GET_AVATARS = "GET_AVATARS";
export const GET_AVATAR = "SET_AVATAR";
export const CREATE_AVATAR = "CREATE_AVATAR";
export const MODAL_AVATAR = "MODAL_AVATAR";
export const VALIDATE_IMAGE = "VALIDATE_IMAGE";
export const DELETE_AVATAR = "DELETE_AVATAR";

// PRESENTER
export const GET_PRESENTERS = "GET_PRESENTERS";
export const BEFORE_PRESETNTER = "BEFORE_PRESENTER";
export const CREATE_PRESENTER = "CREATE_PRESENTER";
export const SELECT_PRESENTER = "SELECT_PRESENTER";
export const DELETE_PRESENTER = "DELETE_PRESENTER";

// VOICE
export const BEFORE_VOICE = "BEFORE_VOICE";
export const GET_VOICE = "GET_VOICE";
export const GET_VOIECS = "GET_VOICES";
export const GET_VOICE_DROPDOWN = "GET_VOICE_DROPDOWN";
export const LISTEN_VOICE = "LISTEN_VOICE";

// PAYMENT
export const CREATE_PAYMENT = "CREATE_PAYMENT";
export const BEFORE_PAYMENT = "BEFORE_PAYMENT";
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const INVOICE_PRINT = "INVOICE_PRINT";
export const LIST_PAYMENT = "LIST_PAYMENT";
export const GET_PAYMENT = "GET_PAYMENT";
export const UPDATE_SIDEBAR = "UPDATE_SIDEBAR";
export const SET_CREATE_PAYMENT = "SET_CREATE_PAYMENT";

// PLANS
export const BEFORE_PLAN = "BEFORE_PLAN";
export const GET_PLANS = "GET_PLANS";
export const CREATE_PLAN = "CREATE_PLAN";
export const PLAN_DISCOUNT = "PLAN_DISCOUNT";
export const PLAN_INDEX = "PLAN_INDEX";

// CREATE VIDEO FORM DATA
export const SUBMIT_FORM_DATA = "SUBMIT_FORM_DATA";
export const SET_TITLE = "SET_TITLE";
export const SET_IMAGE = "SET_IMAGE";
export const SET_TEXT = "SET_TEXT";
export const SET_VOICE = "SET_VOICE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_STYLE = "SET_STYLE";
export const SET_AUDIO_FILE = "SET_AUDIO_FILE";
export const SET_AUDIO_INPUT = "SET_AUDIO_INPUT";
export const BEFORE_FORM_DATA = "BEFORE_FORM_DATA";
export const DISCARD_FORM_DATA = "DISCARD_FORM_DATA";
export const CREATE_VIDEO = "CREATE_VIDEO";
export const VIDEO_ERROR = "VIDEO_ERROR";
export const SET_VIDEO_ERROR = "SET_VIDEO_ERROR";
//Videos
export const GET_VIDEOS = "GET_VIDEOS";
export const DELETE_VIDEO = "DELETE_VIDEO";
export const DOWNLOAD_VIDEO = "DOWNLOAD_VIDEO";
export const COUNT_VIDEOS = "COUNT_VIDEOS";

// Faqs
export const GET_FAQS = "GET_FAQS";
export const CREATE_FAQS = "CREATE_FAQS";
export const DELETE_FAQS = "DELETE_FAQS";
export const UPDATE_FAQS = "UPDATE_FAQS";

// GPT
export const GET_GPT = "GET_GPT";
export const BEFORE_GPT = "BEFORE_GPT";

// Contact-Us
export const GET_CONTACTS = "GET_CONTACTS";
export const CREATE_CONTACT = "CREATE_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
