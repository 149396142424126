import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ENV } from "../../config/config";
import { Images } from "../../assets/asset";
import "./trusted-by.css";
const TrustedBy = () => {
	return (
		<div className="trusted-by d-id">
			<Container>
				<Row className="align-items-center">
					<Col lg={6}>
						<div>
							<img className="img-fluid" src={Images.Brands} alt="brands" />
						</div>
					</Col>
					<Col lg={6}>
						<div>
							<span>Trusted by</span>
							<h2 className="trusted-by-heading text-start">Industry Leaders</h2>
							<p className="mb-5"></p>
							<p>{ENV?.appName} is one of the world’s most reliable AI solutions for digital avatars and visual content creation. It has an extensively expanding user base worldwide, spanning a wide range of businesses. Industry leaders from education to entertainment, marketing, and healthcare, to name a few, are widely adopting our ingeniously built suite of AI tools, making it a preferred choice for businesses finding the best in AI-avatar generation.</p>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
export default TrustedBy;
