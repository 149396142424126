import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Images } from "../../assets/asset";
import { ENV } from "../../config/config";

import PageBanner from "../page-banner/page-banner";

const PrivacyPolicy = () => {
  return (
		<div className='wrapper cms-page'>
			<PageBanner headingFirstPart="Privacy" headingLastPart="Policy" />
			<section className="content-section">
				<Container>
					<Row className="align-items-center">
						<Col lg={6}>
							<h2>Introduction and Data Processing</h2>
							<p className="privacy-statement-para">This Privacy Policy is aimed at providing clarity and transparency regarding the handling of personal data by the {ENV?.appName} platform. We will explore data types processed, purposes of processing, legal basis, and general provisions to ensure that you are informed about your online privacy rights and the measures in place to protect your data.</p>
						</Col>
						<Col lg={6}>
							<div className="d-flex justify-content-center">
								<img src={Images.privacyPolicyImg} alt="privacyPolicyImg" />
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="content-section">
				<Container>
					<h2>Personal Data Processing</h2>
					<h3>Types of Personal Data Processed and Purposes</h3>
					<p>{ENV?.appName}, as a responsible entity, processes various types of personal data in line with the principles of data protection. Personal data can be categorized into different types, depending on the nature and purpose of processing. Here are the key types of personal data we may process:</p>
					<ul className="list-unstyled content-bullets mb-3 mb-md-5">
						<li className="d-flex flex-column">
							<strong>Identity Data</strong>
							<span>This includes information such as your first name, last name, username, or similar identifiers.</span>
						</li>
						<li className="d-flex flex-column">
							<strong>Contact Data</strong>
							<span>Contact data comprises details like billing address, email address, and telephone numbers.</span>
						</li>
						<li className="d-flex flex-column">
							<strong>Financial Data</strong>
							<span>We may process financial data, including bank account and payment card details.</span>
						</li>
						<li className="d-flex flex-column">
							<strong>Transaction Data</strong>
							<span>This encompasses specifics about payments and services purchased.</span>
						</li>
						<li className="d-flex flex-column">
							<strong>Commercial Data</strong>
							<span>For business-related users, we collect information like the company name, business purpose, and requirements for video generation.</span>
						</li>
						<li className="d-flex flex-column">
							<strong>Profile Data</strong>
							<span>This involves data related to your user profile, such as username, password, preferences, feedback, and survey responses.</span>
						</li>
						<li className="d-flex flex-column">
							<strong>Usage Data</strong>
							<span>We collect information about how you use our website, products, and services.</span>
						</li>
						<li className="d-flex flex-column">
							<strong>Audio/Video Data</strong>
							<span>In cases where you upload content as a data source for video generation, this data may be processed.</span>
						</li>
						<li className="d-flex flex-column">
							<strong>Technical Data</strong>
							<span>Technical data encompasses information like your IP address, login data, browser, time zone settings, and operating system used for access.</span>
						</li>
						<li className="d-flex flex-column">
							<strong>Other Information</strong>
							<span>Any additional information you choose to provide falls under this category.</span>
						</li>
					</ul>
					<p>These categories encompass the range of personal data we may collect as part of our operations. The processing of this data serves several essential purposes, including:</p>
					<ul className="list-unstyled content-bullet-points mb-3 mb-md-5">
						<li>Providing access to our Services.</li>
						<li>Fulfilling specific service requests.</li>
						<li>Managing user accounts.</li>
						<li>Processing payments for services.</li>
						<li>Offering customer support.</li>
						<li>Sending information about the Services.</li>
						<li>Sending advertising emails.</li>
						<li>Analyzing and improving our services technically.</li>
						<li>Meeting legal obligations.</li>
					</ul>
					<h2>Data Security, Privacy Rights, and Policy Changes</h2>
					<h3>Security Measures, Privacy Rights, and Policy Updates</h3>
					<p>Ensuring the security and privacy of your personal data is a top priority for {ENV?.appName}. Here's a closer look at the measures in place to protect your data, your privacy rights, and our approach to policy updates:</p>
					<h4>Security Measures</h4>
					<p>We have implemented rigorous security procedures and measures to safeguard your personal data against misuse, unauthorized access, disclosure, or modification. These measures are in line with best practices in data security. Only authorized personnel have access to your data. However, it's important to note that no data security measures can guarantee 100% protection, and in the event of a data breach, we are committed to notifying the appropriate authorities and affected users within 72 hours of becoming aware of such an incident.</p>
					<h4>Children's Privacy</h4>
					<p>Our Services are intended for a general audience and are not directed at children under the age of 16. We do not knowingly collect information from minors or allow them to use our services.</p>
					<h4>Cookies and Third-Party Services</h4>
					<p>We use cookies and similar technologies to enhance your browsing experience. Cookies store information about your visit to our website and help us improve our services. While necessary cookies are used immediately upon accessing our site, we seek your consent to use analytics cookies. You have the ability to manage your cookie preferences through your browser or device settings. However, disabling cookies may affect certain services' functionality.</p>
					<h4>Storage and Transfer of Personal Data</h4>
					<p>Your personal data is stored on secure servers with privacy safeguards in place. The retention period for your data varies based on whether you have an active account, regulatory requirements, or legitimate interests. We maintain personal data integrity even after deactivation to address appeals or requests associated with your previous use.</p>
					<h4>User's Rights</h4>
					<p>We process all personal data in compliance with state regulations and respect your rights, including the right to access, data portability, rectification, erasure, objection, and notification regarding automated decision-making. To exercise these rights, you can contact us at the provided email address. We aim to fulfill your requests promptly and free of charge unless excessive or repetitive requests necessitate a reasonable fee.</p>
					<h4>Changes to the Privacy Policy</h4>
					<p>We retain the right to modify our Privacy Policy at any time. The most up-to-date version is always available on our website, with the effective date clearly indicated. We encourage you to check our Privacy Policy periodically to stay informed about any updates or changes.
					If you have any questions or concerns regarding your privacy, you can contact us at [<a className="theme-green-color" href="mailto:contact@companyname.com">contact@company.com</a>].</p>
				</Container>
			</section>
		</div>
	)
};

export default PrivacyPolicy;