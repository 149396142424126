import React, { useEffect } from "react";
import Header from "../../components/studio-d-id/studio-header/header";
import Sidebar from "../../components/studio-d-id/studio-sidebar/sidebar";
import { useDispatch } from "react-redux";
import { getPayment } from "../../components/stripe-payment/stripe-payment.action";
const Layout3 = (props) => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken") || "";

  useEffect(() => {
    if (accessToken) dispatch(getPayment());
  }, [accessToken, dispatch]);
  return (
    <div className="studio-main-wrapper d-flex">
      <Sidebar />
      <div className="studio-content">
        <Header />
        {props.children}
      </div>
    </div>
  );
};

export default Layout3;
