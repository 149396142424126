import React from "react";
const Layout6 = (props) => {
  return (
    <>
      {props.children}
    </>
  );
};

export default Layout6;
