import {
  VERIFY_USER,
  UPDATE_PASSWORD,
  FORGOT_PASSWORD,
  LOGIN_USER,
  GOOGLE_SOCIAL,
  BEFORE_USER,
  SIGNUP_USER,
} from "../../redux/types";
const initialState = {
  user: null,
  getUserAuth: false,
  setUserAuth: false,
  createUserAuth: false,
  updatePasswordFlag: false,
  forgotPasswordFlag: false,
  verifyAccountFlag: false,
  googleSocialFlag: false,
};
const  userReducer = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_USER:
      return {
        ...state,
        verifyAccountFlag: true,
      };
    case LOGIN_USER:
      return {
        ...state,
        getUserAuth: true,
      };
    case GOOGLE_SOCIAL:
      return {
        ...state,
        googleSocialFlag: true,
      };
    case UPDATE_PASSWORD:
      return {
        ...state,
        updatePasswordFlag: true,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        forgotPasswordFlag: true,
      };
    case BEFORE_USER:
      return {
        ...state,
        user: null,
        getUserAuth: false,
        createUserAuth: false,
        setUserAuth: false,
        updatePasswordFlag: false,
        verifyAccountFlag: false,
        googleSocialFlag: false,
      };
    case SIGNUP_USER:
      return {
        ...state,
        createUserAuth: true,
      };
    default:
      return {
        ...state,
      };
  }
}
export default userReducer