import { GET_FAQS } from "../../redux/types";
const initialState = {
  faqs: null,
  getFaqsAuth: false,
};
const faqsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FAQS:
      return {
        ...state,
        faqs: action.payload,
        getFaqsAuth: true,
      };
    default:
      return {
        ...state,
      };
  }
}
export default faqsReducer
