import {
  GET_VOIECS,
  BEFORE_VOICE,
  GET_ERRORS,
  GET_VOICE_DROPDOWN,
  LISTEN_VOICE,
} from "../../redux/types";
import { ENV } from "../../config/config";
import { toast } from "react-toastify";
export const beforeVoice = () => {
  return {
    type: BEFORE_VOICE,
  };
};
export const getVoices = () => (dispatch) => {
  const url = `${ENV.backendURL}voices/get-voice-list`;
  fetch(url, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
      "x-access-token": localStorage.getItem("accessToken") || "",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        // toast.success(data.message);
        dispatch({
          type: GET_VOIECS,
          payload: data.data,
        });
      } else {
        toast.error(data.message);
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};
export const voiceDropDownList = () => (dispatch) => {
  const url = `${ENV.backendURL}voices/get-voice-dropdown-list`;
  fetch(url, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
      "x-access-token": localStorage.getItem("accessToken") || "",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        // toast.success(data.message);
        dispatch({
          type: GET_VOICE_DROPDOWN,
          payload: data.data,
        });
      } else {
        toast.error(data.message);
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};
export const listenVoices = (body) => (dispatch) => {
  const url = `${ENV.backendURL}voices/listen`;
  fetch(url, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
      "x-access-token": localStorage.getItem("accessToken") || "",
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: LISTEN_VOICE,
          payload: data.data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};
