import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Images } from "../../assets/asset";
import { ENV } from "../../config/config";
import PageBanner from "../page-banner/page-banner";

const TermsConditions = () => {
	return (
		<div className="wrapper cms-page">
			<PageBanner headingFirstPart="Terms" headingMiddlePart="&" headingLastPart="Conditions" />
			<section className="content-section">
				<Container>
					<Row className="align-items-center">
						<Col lg={6}>
							<h2>Introduction</h2>
							<p className="privacy-statement-para">Welcome to {ENV?.appName}'s software and applications ("Software"), collectively referred to as "{ENV?.appName}," "we," "us," or "our." Please read this Agreement carefully before installing or using the Software. By clicking "accept" or "ok," installing, or using the Software, you acknowledge that you are entering into a legal agreement with {ENV?.appName} and agree to be bound by the terms and conditions outlined herein. This Agreement also covers the rights and responsibilities of users, and by accepting it, you waive the right to require non-electronic signatures or delivery of non-electronic records, unless prohibited by law.</p>
						</Col>
						<Col lg={6}>
							<div className="d-flex justify-content-center">
								<img src={Images.companyTermsImg} alt="privacyPolicyImg" />
							</div>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="content-section">
				<Container>
					<h2>End-User License Agreement for {ENV?.appName} Software</h2>
					<h3>Background</h3>
					<p>The Software allows you to use generated outputs ("Output") based on text and/or audio input provided by you, as well as create animations and videos ("Animations") from still photos, text, and/or audio, using templates of movement provided by {ENV?.appName} ("Templates"). Your use of the Software is subject to the credits granted according to your subscription plan ("Plan"). {ENV?.appName}aims to deliver finished Animations within five minutes from submission.</p>
					<h3>Ability to Accept</h3>
					<p>By installing and using the Software, you confirm that you are at least eighteen (18) years of age. If you are under eighteen (18), please do not install or use our Software.</p>
					<h3>Software Right of Use</h3>
					<p>Subject to the terms of this Agreement, {ENV?.appName} grants you a limited, personal, revocable, non-exclusive, non-assignable, non-transferable right of use ("Right of Use") for the applicable Software product. Your specific Rights of Use depend on your user category:</p>
					<h4>Guest User:</h4>
					<p>If you register an Account for a trial Plan, you are considered a "Guest User" with limited features for non-commercial use, available for two weeks from your registration date. Animations for Guest Users will include Watermarks.</p>
					<h4>Paying User:</h4>
					<p>Registering an Account for a paid Plan makes you a "Paying User." Paying Users are subject to subscription payments based on {ENV?.appName}'s Price List, and they can use the Software as outlined in their selected Plan. Animations for Paying Users will not have Watermarks.</p>
					<h4>Change of Plans:</h4>
					<p>You can change your Plan through the Software or by contacting us at [<a className="theme-green-color" href="mailto:contact@companyname.com">contact@company.com</a>], with changes subject to the applicable subscription fee.</p>
					<h3>Usage Restrictions</h3>
					<h4>General:</h4>
					<p>You agree not to transfer, redistribute, sell, lease, lend, or rent the Software, make it available over a network for multiple users, disassemble, reverse engineer, decompile, decrypt, copy (except for backup purposes), modify, improve, or create derivative works, circumvent security features, remove proprietary notices, use for unauthorized commercial communications, or use the Software in violation of applicable laws or for unlawful or inappropriate purposes.</p>
					<h4>Synthetic and Watermarks:</h4>
					<p>Animations contain a synthetic mark to clarify their edited nature. Guest User Animations include Watermarks. You may not remove, hide, or minimize the Synthetic Mark and/or Watermarks without {ENV?.appName}'s prior written approval.</p>
					<h4>App Usage Rules:</h4>
					<p>If the Applicable Product is a mobile software application, you must comply with the Usage Rules set by the Distributor from which you downloaded the App. These Usage Rules are incorporated into this Agreement. You represent that you are not prohibited by any Usage Rules or applicable law from using the App.</p>
					<h3>Account</h3>
					<p>To use Software features, you must create an Account. You may not create an Account for others or use another person's Account without permission. You are responsible for Account activity and security. You must notify us immediately of any unauthorized Account use. To delete your Account and terminate this Agreement, contact us at [<a className="theme-green-color" href="mailto:contact@companyname.com">contact@company.com</a>].</p>
					<h3>Location Data</h3>
					<p>Certain Software features may collect or depend on Location Data. By using these features, you agree to provide or make your Location Data accessible to us, subject to our Privacy Policy. Failure to provide Location Data may limit feature functionality.</p>
					<h3>Intellectual Property Rights</h3>
					<h4>Ownership:</h4>
					<p>{ENV?.appName} and its licensors retain all rights and Intellectual Property Rights in the Software. "Intellectual Property Rights" include trade secrets, patents, copyrights, trademarks, and similar rights. Your use of the Software does not grant ownership.</p>
					<h4>Software Content:</h4>
					<p>The Software Content, including text, information, documents, graphics, photos, and trademarks, is the property of {ENV?.appName} and its licensors, and may be protected by copyright laws. Inputs, Outputs, User Submissions, and Animations are not considered Software Content.</p>
					<h4>Use of Content:</h4>
					<p>Software Content may not be used, copied, distributed, modified, or exploited for other purposes without prior written consent, except as provided in this Agreement.</p>
					<h3>Payments</h3>
					<h4>Charges:</h4>
					<p>You will not be charged for Software use unless you agree to applicable charges. {ENV?.appName} reserves the right to apply charges for Software use in the future.</p>
					<h4>Subscription Fee:</h4>
					<p>Paying Users must pay a Subscription Fee as specified in the Price List. Plans can be on a monthly or annual basis, with automatic renewal for successive Billing Periods. Unused Credits expire at the end of each Billing Period. You can cancel your subscription to avoid future charges.</p>
					<h4>Change of Plans:</h4>
					<p>Changing Plans is subject to the new Plan's Subscription Fee. Additional charges may apply when upgrading to a higher Plan or transitioning from monthly to annual billing.</p>
					<h4>Payment Processor:</h4>
					<p>Payments are processed through a third-party Payment Processor (currently Stripe.com), and {ENV?.appName} is not responsible for their acts or omissions. Payment Processor services are governed by their Terms of Service.</p>
					<h4>Account Suspension:</h4>
					<p>Failure to pay amounts may result in Account suspension until the issue is resolved. Payments are irrevocable and nonrefundable unless explicitly provided otherwise.</p>
					<h3>Information Description</h3>
					<p>While we aim for accuracy, we do not warrant that Software Content, Outputs, and Animations are error-free, complete, or up-to-date. We may make changes to Software Content without notice.</p>
					<h3>Privacy; Security</h3>
					<h4>Privacy:</h4>
					<p>We collect and use personal information in accordance with our Privacy Policy. You are not obligated to provide personal information but do so at your own discretion.</p>
					<h4>Security:</h4>
					<p>Additional information on security measures is available on our FAQ page.</p>
					<h3>Copyright Policy</h3>
					<h4>Removal of Content:</h4>
					<p>{ENV?.appName} respects copyright owners' rights and responds to clear notices of alleged copyright infringement, following the DMCA. If you believe your work has been infringed, please provide the necessary information.</p>
					<h4>Counter-Notification:</h4>
					<p>If your material was removed by mistake and you have the rights to it, you can send a counter-notification.</p>
					<h4>Misrepresentations:</h4>
					<p>Knowingly misrepresenting copyright infringement may result in liability under state regulations.</p>
					<h4>Copyright Agent:</h4>
					<p>Our Copyright Agent for notices of alleged copyright infringement can be reached at [<a className="theme-green-color" href="mailto:contact@companyname.com">contact@company.com</a>]</p>
					<h3>Warranty Disclaimers</h3>
					<h4>Software and Content Warranty:</h4>
					<p>The software, software content, outputs, and animations are provided on an "as is" and "as available" basis without any warranties, whether express or implied. This includes representations, warranties, and conditions of merchantability, fitness for a particular purpose, title, non-infringement, and those arising from statute or from a course of dealing or usage of trade.</p>
					<h4>Error-Free Operation:</h4>
					<p>We do not warrant that the software will operate without errors, that it is free from viruses or other harmful code, or that we will rectify any errors in the software. The software may occasionally be unavailable for routine maintenance, upgrades, or other reasons. Unless explicitly agreed upon in writing or as part of your subscription, we do not commit to specific uptime or availability, including during disasters or business continuity situations. You acknowledge that we are not liable for any consequences resulting from technical issues, including internet-related problems (such as slow connections, traffic congestion, or server overloads) or issues with telecommunications or internet service providers.</p>
					<h4>Content Endorsement:</h4>
					<p>{ENV?.appName} does not warrant, endorse, or guarantee any content that appears in an input, output, user submission, and/or animations and disclaims all liability for such content.</p>
					<h4>User Conduct:</h4>
					<p>You acknowledge that {ENV?.appName} is not responsible for the conduct of any software user, including defamatory, offensive, illegal, or negligent conduct. The risk of harm or damage resulting from such conduct rests with you.</p>
					<h4>User Reliance:</h4>
					<p>Your reliance on or use of any input, output, user submission, and/or animations, or interaction with any software user or owner, is at your own risk. If you have a dispute with another software user or owner related to the software or any content, you agree that we are not liable for any claims or damages arising from such a dispute. We reserve the right, without obligation, to monitor such disputes.</p>
					<h4>Security:</h4>
					<p>Except as expressly stated in our privacy policy, {ENV?.appName} does not make representations, warranties, or conditions, express or implied, regarding the security of information you provide or activities you engage in while using the software.</p>
					<h4>Application of Section:</h4>
					<p>This section applies regardless of whether the services provided under the software are for payment.</p>
					<h4>Legal Exemptions:</h4>
					<p>Applicable law may restrict the exclusion of certain warranties, and to that extent, such exclusions may not apply.</p>
					<h3>Limitation of Liability</h3>
					<h4>Special, Indirect, and Consequential Damages:</h4>
					<p> {ENV?.appName} shall not, under any circumstances, be liable for any special, direct, indirect, incidental, punitive, or consequential damages, or for any loss of data, revenue, business, or reputation that arises under or in connection with this agreement or results from the use of or inability to use the software. Some jurisdictions may not allow the limitation or exclusion of liability for incidental or consequential damages, so the above limitations may not apply to you.</p>
					<h4>Total Liability:</h4>
					<p>In any event, {ENV?.appName}'s total aggregate liability for all damages and losses arising under or in connection with this agreement or resulting from your use or inability to use the software shall not, under any circumstances, exceed the total amounts, if any, actually paid by you to {ENV?.appName} for using the software within the twelve (12) months preceding the date of bringing a claim.</p>
					<h3>Indemnity</h3>
					<p>When you use {ENV?.appName}'s software, it's crucial to understand the responsibility and protection aspects outlined in the Indemnity section. You're agreeing to defend, indemnify, and hold harmless {ENV?.appName}, its affiliates, officers, directors, employees, and agents (against various claims and damages). These include claims related to your use of the software, your interactions with other users, and potential violations of this agreement or third-party rights. It's essential to note that {ENV?.appName} has the option, but not the obligation, to assume control of defense in such cases, should you choose not to do so. However, you must obtain {ENV?.appName}'s approval before settling any matter subject to indemnification.</p>
					<h3>Modification</h3>
					<p>{ENV?.appName} reserves the right to modify this agreement at any time. They will notify you through in-software notifications, emails, or by publishing the revised agreement within the software. These changes will become effective ten days after notification, and by continuing to use the software, you are considered to have accepted these changes.</p>
					<h3>Export Laws</h3>
					<p>You must comply with all applicable export laws and regulations. This is to ensure that you do not export or re-export the software or any related technical data in violation of these laws. Doing so could result in serious legal consequences.</p>
					<h3>Updates and Upgrades</h3>
					<p>{ENV?.appName} may provide software updates or upgrades periodically, but they are not obligated to do so. These revisions will be supplied according to their policies, which may include automatic updates without additional notice. By using the software, you consent to these automatic updates. It's important to note that all references to the software in this agreement also include any revisions. The terms of this agreement will govern any revisions that replace or supplement the original software unless a separate agreement accompanies the revision.</p>
					<h3>Third Parties</h3>
					<p>{ENV?.appName}'s software may include components from third-party sources. It's crucial to understand how these third-party sources may impact your experience:</p>
					<h4>Open-Source Software:</h4>
					<p>Some parts of the software may contain third-party open-source software governed by their terms and conditions. In case of a conflict between these third-party terms and {ENV?.appName}'s terms, the third-party terms prevail, but only concerning the related open-source software. {ENV?.appName} provides no warranty or indemnity for third-party open-source software.</p>
					<h4>Third-Party Sources:</h4>
					<p>The software allows you to access and interact with content from third-party sources, including partners and customers. These third-party sources are not controlled by {ENV?.appName}, and they bear no responsibility for the content, privacy policies, or actions of these sources. You should familiarize yourself with their terms of use and privacy policies before engaging with them. Additionally, third-party sources might use your user submissions and animations for their purposes, such as the development and improvement of their services. {ENV?.appName} does not endorse any materials included in third-party content.</p>
					<h4>Allowed Links:</h4>
					<p>{ENV?.appName} permits you to link to the software as long as you follow specific guidelines. These include accurately describing the software, not misrepresenting your relationship with {ENV?.appName}, and ensuring that the third-party sources from which you link comply with the agreement and applicable laws.</p>
					<h3>Support</h3>
					<p>If you have inquiries or require support, you can contact {ENV?.appName} at [<a className="theme-green-color" href="mailto:contact@companyname.com">contact@company.com</a>]. They will make reasonable efforts to address your inquiries and provide support as specified in your plan. It's important to note that the terms in the User Submissions section also apply to inquiries sent via the software.</p>
					<h3>User Submissions</h3>
					<p>User submissions are a key part of the software. This section outlines important aspects:</p>
					<h4>Submission and Sharing:</h4>
					<p>The software allows the submission and sharing of user-generated content, including input and animations. This content may be made publicly available, and {ENV?.appName} cannot guarantee confidentiality.</p>
					<h4>Ownership and Responsibility:</h4>
					<p>You must own or have the necessary rights and permissions to use your user submissions. You retain ownership rights in your submissions, but you are responsible for obtaining any required consents, rights, and licenses.</p>
					<h4>Monitoring:</h4>
					<p>{ENV?.appName} may use monitoring tools to verify fair use and limit prohibited content. If any user submissions conflict with the agreement, you may be asked to provide a statement to confirm your rights to upload the content.</p>
					<h4>Right to Use User Submissions:</h4>
					<p>By submitting user submissions, you grant {ENV?.appName} the right to use, reproduce, and distribute the content as specified in the agreement. Other software users and third-party sources also receive non-exclusive rights to use your user submissions in line with the agreement.</p>
					<h4>Exposure:</h4>
					<p>You should be aware that you may encounter user submissions from various sources, and {ENV?.appName} is not responsible for their accuracy or content. Your interaction with user submissions is at your discretion and risk.</p>
					<h4>Disclosure:</h4>
					<p>{ENV?.appName} may access and disclose user submissions and related information for various reasons, including legal compliance and user support inquiries.</p>
					<h4>Prohibited Content:</h4>
					<p>The software prohibits certain types of content, such as content that infringes on intellectual property rights, promotes illegal activities, or is offensive or fraudulent.</p>
					<h3>Publicity</h3>
					<p>{ENV?.appName} reserves the right to use your name, trademark, and logo to refer to you as a customer of {ENV?.appName} in various promotional materials and presentations.</p>
					<h3>Term and Termination</h3>
					<p>This agreement is effective until terminated by either party. {ENV?.appName} may discontinue or modify the software, terminate this agreement, or terminate your subscription or account for any reason. They will provide notice before terminating a paying user's subscription.</p>
					<h3>Independent Contractors</h3>
					<p>You and {ENV?.appName} are considered independent contractors, not partners, joint ventures, or employers. You must not make commitments or warranties on behalf of {ENV?.appName}.</p>
					<h3>Assignment</h3>
					<p>You cannot transfer or assign this agreement, but {ENV?.appName} can do so without restriction or notification.</p>
					<h3>Governing Law and Disputes</h3>
					<p>The governing law for this agreement depends on your location. {ENV?.appName} may seek injunctive relief in any court of competent jurisdiction.</p>
					<h3>General</h3>
					<p>This agreement, along with any legal notices, constitutes the entire agreement between you and {ENV?.appName}. If any provision is deemed invalid, the remaining provisions remain in effect. Any cause of action arising from the software must be initiated within one year.</p>
					<h3>Contact Information</h3>
					<p>For questions or complaints, you can contact {ENV?.appName} at [<a className="theme-green-color" href="mailto:contact@companyname.com">contact@company.com</a>]</p>

				</Container>
			</section>
		</div>
	);
};

export default TermsConditions;