import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { faFaceSmileWink } from "@fortawesome/free-regular-svg-icons";
import { getPayment } from "../stripe-payment/stripe-payment.action";
import { getAvatars } from "../avatar/avatar.action";
import {
  faDollarSign,
  faRightToBracket,
  faRightFromBracket,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
const InnerSideBar = ({
  setShow,
  Images,
  updateSideBarAuth,
  toggleAvatarHolder,
}) => {
  const dispatch = useDispatch();
  const [personalData, setPersonalData] = useState({});
  const payment = useSelector((state) => state.payments?.payment);
  useEffect(() => {
    dispatch(getPayment());
  }, [updateSideBarAuth, dispatch]);
  useEffect(() => {
    let tempObj = {};
    tempObj.name = localStorage.getItem("name");
    tempObj.email = localStorage.getItem("email");
    tempObj.token = localStorage.getItem("accessToken");
    setPersonalData(tempObj);
  }, []);
  const signOut = () => {
    localStorage.clear();
    setPersonalData({ name: "", email: "", token: "" });
  };
  return (
    <div className="inner-sidebar d-flex flex-column">
      <div className="web-cross" onClick={() => setShow(false)}>
        <img className="img-fluid" alt="" src={Images.cross} />
      </div>
      <div className="logo d-flex align-items-center">
        {/* <div className="logo-image">
            <img src={Images.logo} alt="logo" className="img-fluid" />
          </div> */}
        <div className="web-logo">
          <img className="img-fluid" alt="" src={Images.companyLogo} />
        </div>
        <div className="logo-text d-flex flex-column">
          <span>Welcome, {personalData?.name || "guest"}</span>
          <span className="credit-count">
            Credits: {payment?.remainingTextChatTokens || 0}/
            {payment?.totalTextChatTokens || 0}
          </span>
        </div>
      </div>
      <div className="menu">
        <ul>
          <li>
            <Link to="/studio" className="d-flex align-items-center">
              <span className="menu-img">
                <FontAwesomeIcon icon={faShare} />
              </span>
              <span className="menu-text">Studio AR-AI</span>
            </Link>
          </li>
          <li>
            <Link to="/studio/pricing" className="d-flex align-items-center">
              <span className="menu-img">
                <FontAwesomeIcon icon={faDollarSign} />
              </span>
              <span className="menu-text">Pricing</span>
            </Link>
          </li>
          <li>
            <Link
              className="d-flex align-items-center"
              to=""
              onClick={() => {
                toggleAvatarHolder();
                dispatch(getAvatars());
              }}
            >
              <span className="menu-img">
                <FontAwesomeIcon icon={faFaceSmileWink} />
              </span>
              <span className="menu-text">Choose your avatar</span>
            </Link>
          </li>

          <li>
            {personalData?.token ? (
              <Link
                to="/login"
                onClick={signOut}
                className="d-flex align-items-center"
              >
                <span className="menu-img">
                  <FontAwesomeIcon icon={faRightFromBracket} />
                </span>
                <span className="menu-text">Sign out</span>
              </Link>
            ) : (
              <Link to="/login">
                <span className="menu-img">
                  <FontAwesomeIcon icon={faRightToBracket} />
                </span>
                <span className="menu-text">Sign in / Sign up</span>
              </Link>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InnerSideBar;
