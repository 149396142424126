import React from "react";
import { Container} from "react-bootstrap";
import { Link } from "react-router-dom";
import { ENV } from "../../config/config";
import { Images } from "../../assets/asset";
import "./footer.css";
const Footer = () => {
	return (
		<footer id="footer">
			<div className="home-footer">
				<div className="bg-icon">
					<img src={Images.BgIcon} alt="icon" />
				</div>
				<Container>
					<div className="mb-3 mb-md-5 d-flex justify-content-center align-items-center">
						<Link to="/" className="d-flex justify-content-center align-items-center">
							<strong className="mb-2 d-block footer-logo d-flex justify-content-center align-items-center">
								<img className="img-fluid" src={Images.whiteLogo} alt="logo" />
							</strong>
						</Link>
					</div>
					<ul className="footer-links-ul d-flex justify-content-center flex-wrap list-unstyled">
						<li><Link className="nav-link" to="/studio">Studio</Link></li>
						<li><Link className="nav-link" to="/ar-ai">Chat</Link></li>
						<li><Link className="nav-link" to="/studio/pricing">Pricing</Link></li>
						<li><Link className="nav-link" to="/studio/faqs">Faqs</Link></li>
						<li><Link className="nav-link" to="/studio/contact">Contact</Link></li>
						<li><Link className="nav-link" to="/terms-conditions">Terms &amp; Conditions</Link></li>
						<li><Link className="nav-link" to="/privacy-policy">Privacy Policy</Link></li>
					</ul>
					<span className="d-block mb-3"></span>
				</Container>
			</div>
			<div className="footer-bottom text-white text-center">
				<Container>
					<p className="m-0">{ENV?.appName} | &copy; 2023 All Rights Reserved</p>
				</Container>
			</div>
		</footer>
	);
};
export default Footer;
