import React from "react";
import { Link } from "react-router-dom";
import { faMicrosoft, faRocketchat } from "@fortawesome/free-brands-svg-icons";
import {
  faDollarSign,
  faPlus,
  faList,
  faContactBook,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function MainMenu({ currentPathname }) {
  return (
    <div className="main-menu">
      <Link
        to="/ar-ai"
        className={`menu-button ${
          currentPathname === "/ar-ai" ? "active" : ""
        }`}
      >
        <span className="menu-img">
          <FontAwesomeIcon icon={faRocketchat} />
        </span>
        <span className="menu-tag">AR-AI</span>
      </Link>
      <Link
        to="/studio/editor"
        className={`menu-button ${
          currentPathname === "/studio/editor" ? "active" : ""
        }`}
      >
        <span className="menu-img">
          <FontAwesomeIcon icon={faPlus} />
        </span>
        <span className="menu-tag">Create Video</span>
      </Link>
      <Link
        to="/studio"
        className={`menu-button ${
          currentPathname === "/studio" ? "active" : ""
        }`}
      >
        <span className="menu-img">
          <FontAwesomeIcon icon={faMicrosoft} />
        </span>
        <span className="menu-tag">Video Library</span>
      </Link>
      <Link
        to="/studio/pricing"
        className={`menu-button ${
          currentPathname === "/studio/pricing" ? "active" : ""
        }`}
      >
        <span className="menu-img">
          <FontAwesomeIcon icon={faDollarSign} />
        </span>
        <span className="menu-tag">Pricing</span>
      </Link>
      <Link
        to="/studio/faqs"
        className={`menu-button ${
          currentPathname === "/studio/faqs" ? "active" : ""
        }`}
      >
        <span className="menu-img">
          <FontAwesomeIcon icon={faList} />
        </span>
        <span className="menu-tag">FAQs</span>
      </Link>
      <Link
        to="/studio/contact"
        className={`menu-button ${
          currentPathname === "/studio/contact" ? "active" : ""
        }`}
      >
        <span className="menu-img">
          <FontAwesomeIcon icon={faContactBook} />
        </span>
        <span className="menu-tag">Contact Us</span>
      </Link>
    </div>
  );
}

export default MainMenu;
