import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ENV } from "../../config/config";
import { Images } from "../../assets/asset";
import "./banner.css";
export default function Banner() {
	return (
		<div className="banner">
			<Container>
				<Row className="align-items-center">
					<Col lg={5}>
						<div className="content">
							<h1>Interactive Avatars <strong>Powered by Generative AI</strong></h1>
							<p>Create and introduce your simulated avatars to the world with {ENV?.appName}. We present an all-inclusive solution for generating fully-rigged digital personas from photos.</p>
						</div>
						<div className="d-flex banner-btns-wrapper">
							<Link to="/ar-ai" className="filled-btn me-3 mb-2 d-inline-block hvr-shutter-in-horizontal">
								Try the Chat Now!
							</Link>
							<Link to="/studio" className="outlined-btn mb-2 d-inline-block hvr-bounce-to-right">
								Try the Studio
							</Link>
						</div>
					</Col>
					<Col lg={7}>
						<div className="position-relative banner-img-wrapper d-flex justify-content-center">
							<img className="banner-video-img" src={Images.BannerScreen} alt="screen" />							
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}
