import {
  GET_PLANS,
  BEFORE_PLAN,
  PLAN_DISCOUNT,
  PLAN_INDEX,
} from "../../redux/types";
const initialState = {
  plan: null,
  plans: null,
  getPlansAuth: false,
  discountFlag: true,
  subPlanIntex: 0,
};
const planReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLANS:
      return {
        ...state,
        plans: action.payload,
        getPlansAuth: true,
      };
    case BEFORE_PLAN:
      return {
        ...state,
        plan: null,
        plans: null,
        getPlansAuth: false,
      };
    case PLAN_DISCOUNT:
      return {
        ...state,
        discountFlag: action.payload,
      };
    case PLAN_INDEX:
      return {
        ...state,
        subPlanIntex: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
export default planReducer