import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  faArrowRight,
  faArrowRightFromBracket,
  faCaretDown,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
function SideFoot({ showMenu, show, accessToken }) {
  const navigate = useNavigate();
  const payment = useSelector((state) => state.payments?.payment);
  const name = localStorage.getItem("name")
    ? localStorage.getItem("name")
    : "Guest";
  const signOut = () => {
    localStorage.clear();
    navigate("/login");
  };
  return (
    <div className="side-foot">
      <button
        onClick={showMenu}
        className={`menu-button ${show ? "menu-opened" : ""}`}
      >
        <span className="guest-tag">      
          <span className="menu-tag">{name}</span>
        </span>
        <span className="arrow">
          <FontAwesomeIcon icon={faCaretDown} />
        </span>
      </button>
      <span className="d-block text-white mb-3">
        Credits: {payment?.remainingCredits ? payment?.remainingCredits : 0}/
        {payment?.totalCredits ? payment?.totalCredits : 0}
      </span>
      <div className={`footer-box d-flex flex-column align-items-center ${show ? "menu-opened" : ""}`}>
        <Link to="/account-settings" className="">
          <span>
            <FontAwesomeIcon icon={faUser} />
          </span>
          <span className="ms-3">Settings & API</span>
        </Link>
        {accessToken ? (
          <button onClick={signOut} className="btn-signout">
            <span>
            <FontAwesomeIcon icon={faArrowRightFromBracket} />
            </span>
            <span className="ms-3">Sign Out</span>
          </button>
        ) : (
          <Link to="/login" className="">
            <span>
              <FontAwesomeIcon icon={faArrowRight} />
            </span>
            <span>Login/Signup</span>
          </Link>
        )}
      </div>
    </div>
  );
}

export default SideFoot;
