import React from "react";
import { Container } from "react-bootstrap";
import { Images } from "../../assets/asset";
import { Link } from "react-router-dom";
import "./create-videos.css";
const CreateVideos = () => {
	return (
		<div className="create-videos d-id">
			<Container>
				<div className="d-flex justify-content-center align-items-center flex-column position-relative">
					<span className="d-block">Create Videos</span>
					<h2 className="create-vedio-heading"> Super-Fast & Easy</h2>
					<p className="mb-5 text-center">We have made online communication quick and easy with AR-AI. Now you can produce photorealistic videos as diverse as your audience without humans, mics, cameras, or studios. Just create and combine your digital clones with AI-generated text and imitate your human creativity for the best.</p>
					<div className="video-holder">
					<img src={Images.createVedioImg} alt="" className="img-fluid"/>
					<Link className="filled-btn hvr-shutter-in-horizontal-white create-vedio-btn " href="/login"> Try Now! Its Free</Link>
					</div>
				</div>
			</Container>
		</div>
	);
};
export default CreateVideos;
