const logo = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697099323/d-id-front/logo_nbzcrs.svg";
const whiteLogo = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697099621/d-id-front/white-logo_hbzy3b.png";
const cross = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697099565/d-id-front/cross_gvm3qn.svg"
const iconMale = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697099594/d-id-front/icon-male_vzkvbl.png"
const iconFemale ="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697099594/d-id-front/icon-female_eigilv.png"
const camera = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697099641/d-id-front/Camera_wo57hd.svg"
const welcomeVideo = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697103981/d-id-front/welcome_video_thumbnail_iy0a1i.png"
const bolt = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697104050/d-id-front/Bolt_qw4nr0.svg"
const placeholder = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697104094/d-id-front/placeholder_tgutf3.png"
const phone = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697104336/d-id-front/phone-icon_gh3rsp.png"
const email = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697104378/d-id-front/email-icon_tkomwy.png"
const square = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697104448/d-id-front/square_xt0bl4.svg"
const vertical = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697104590/d-id-front/vertical_u1bbrf.svg"
const companyTermsImg = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697104640/d-id-front/company-terms-img_kfv2nx.png"
const privacyPolicyImg = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697104668/d-id-front/privacy-policy-img_gipq8o.png"
const companyLogo = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697104849/d-id-front/home-logo_m0r5zh.svg"
const Brand1 = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697105561/d-id-front/brand-1_rtto0x.png"
const Brand2 = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697105559/d-id-front/brand-2_a0dnhw.png"
const Brand3 = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697105560/d-id-front/brand-3_jndjyn.png"
const Brand4 = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697105560/d-id-front/brand-4_avkyrc.png"
const Brand5 = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697105560/d-id-front/brand-5_qiosd2.png"
const Brands = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697105960/d-id-front/industries-logo_tuuiqv.png"
const BannerScreen = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697443418/d-id-front/banner-img_hhavxu.png"
const RobotIcon ="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697106384/d-id-front/robot-icon_azhbss.svg"
const VideoIcon = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697106679/d-id-front/video-icon_h4wj8v.svg"
const BgIcon = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697106897/d-id-front/footer-bg-icon_cwkd9h.png"
const UserDp = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697107614/d-id-front/customer_itex6e.png"
const DigitalCreationImg = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697442452/d-id-front/digital-creation-img_iceh9p.png"
const createVedioImg = "https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697442736/d-id-front/create-vedio-img_axksib.png"
const conversationalAiImg ="https://res.cloudinary.com/arhamsoft-ltd/image/upload/v1697443130/d-id-front/conversational-ai-img_gsgitp.png"
export const Images = {
  logo,
  cross,
  iconMale,
  iconFemale,
  whiteLogo,
  camera,
  welcomeVideo,
  bolt,
  placeholder,
  phone,
  email,
  square,
  vertical,
  companyTermsImg,
  privacyPolicyImg,
  companyLogo,
  Brand1,
  Brand2,
  Brand3,
  Brand4,
  Brand5,
  Brands,
  BannerScreen,
  RobotIcon,
  VideoIcon,
  BgIcon,
  UserDp,
  DigitalCreationImg,
  createVedioImg,
  conversationalAiImg
};
