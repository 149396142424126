import React from "react";
import "./page-banner.css";
import { Container } from "react-bootstrap";
const PageBanner = (props) => {
  return (
    <section id="page-banner">
      <Container>
        <div className="banner-holder d-flex flex-column align-items-center">
            {props.headingMiddlePart ?
                <h1 className="banner-heading">
                    {props.headingFirstPart} <span className="banner-sub-heading">{props.headingMiddlePart}</span> {props.headingLastPart}
                </h1>
                :
                <h1 className="banner-heading">
                    {props.headingFirstPart} <span className="banner-sub-heading">{props.headingLastPart}</span>
                </h1>
            }
        </div>
      </Container>
    </section>
  );
};

export default PageBanner;
