import React, { useState, useEffect } from "react";
import { faArrowLeft, faVolumeHigh } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Images } from "../../assets/asset";
import { toast } from "react-toastify";
import { getVoices, beforeVoice } from "../voice/voice.action";

import {
  getAvatar,
  beforeAvatar,
  getAvatars,
  cerateAvatar,
  validateImg,
  getSingleAvatars,
} from "../avatar/avatar.action";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../loader/loader";
import InnerSidebar from "./Inner-sidebar";
import ChooseAvatar from "./choose-avatar";
const Sidebar = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [runOnceFlag, setRunOnceFlag] = useState(true);
  const [show, setShow] = useState(false);
  const [isActive, setActive] = useState(false);
  const [avatarForm, setAvatarForm] = useState(false);
  const [error, setError] = useState({});
  const [currentAvatar, setCurrentAvatar] = useState();
  const [avatarList, setAvatarList] = useState();
  const [avatarImage, setAvatarImage] = useState();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [voice, setVoice] = useState({});
  const [avatarImageURL, setAvatarImageURL] = useState();
  const [tempState, setTempState] = useState(null);
  const {
    avatar,
    avatars,
    getAvatarAuth,
    getSingleAvatar,
    validateImageFlag,
    validateImage,
    avatarModelFlag,
    upsertAvatarAuth,
  } = useSelector((state) => state.avatar || {});
  const updateSideBarAuth = useSelector(
    (state) => state.payments?.updateSideBarAuth
  );
  useEffect(() => {
    if (avatarImage) {
      dispatch(getVoices());
    }
  }, [avatarImage, dispatch]);
  useEffect(() => {
    if (validateImageFlag) {
      if (validateImage) {
        setAvatarImageURL(URL.createObjectURL(tempState));
        setAvatarImage(tempState);
        setActive(false);
        setAvatarForm(true);
      } else {
        toast.error("Invalid Image");
      }
      dispatch(beforeAvatar());
    }
  }, [validateImageFlag, validateImage, tempState, dispatch]);
  useEffect(() => {
    if (runOnceFlag) {
      dispatch(getSingleAvatars());
      setRunOnceFlag(false);
    }
  }, [runOnceFlag, dispatch]);
  useEffect(() => {
    if (getSingleAvatar) {
      dispatch(getAvatar(avatar));
      setCurrentAvatar(avatar);
      dispatch(beforeAvatar());
      setLoader(false);
    }
  }, [getSingleAvatar, avatar, dispatch]);
  useEffect(() => {
    if (avatarModelFlag || upsertAvatarAuth) {
      if (upsertAvatarAuth) {
        setToDefault();
      }
      dispatch(beforeAvatar());
      dispatch(getAvatars());
      setActive(true);
    }
  }, [avatarModelFlag, upsertAvatarAuth, dispatch]);
  useEffect(() => {
    if (getAvatarAuth) {
      setAvatarList(avatars);
      dispatch(beforeAvatar());
    }
  }, [getAvatarAuth, avatars, dispatch]);
  const toggleAvatarHolder = () => {
    setActive(!isActive);
  };
  const setImage = (e) => {
    if (e.target.files[0]) {
      const allowedImageTypes = ["jpg", "jpeg", "png"];
      const fileNameParts = e.target.files[0].name.split(".");
      const fileExtension =
        fileNameParts[fileNameParts.length - 1].toLowerCase();
      if (allowedImageTypes.includes(fileExtension)) {
        let formData = new FormData();
        formData.append("image", e.target.files[0]);
        dispatch(validateImg(formData));
        setTempState(e.target.files[0]);
      } else {
        toast.error("unsupported file type");
        setError({
          ...error,
          [e.target.name]: "wrong file type selected,select again",
        });
      }
    }
  };
  const avatarSetter = (value) => {
    setCurrentAvatar(value);
    dispatch(getAvatar(value));
    toggleAvatarHolder();
  };
  const avatarDataChecker = () => {
    let flag = true;
    let error = {};
    if (!avatarImage) {
      flag = false;
      error.image = "Upload image";
    }
    if (!name) {
      flag = false;
      error.name = "Enter name";
    }
    if (!voice.id && voice.id !== 0) {
      flag = false;
      error.voice = "Select voice";
    }
    if (description > 1200) {
      flag = false;
      error.description = "The description should be less than 1200 letters";
    }
    if (flag) {
      return true;
    } else {
      setError(error);
      return false;
    }
  };
  const createAvatar = (e) => {
    e.preventDefault();
    const flag = avatarDataChecker();
    if (flag) {
      let formData = new FormData();
      formData.append("image_file", avatarImage);
      formData.append("name", name);
      formData.append("description", description);
      formData.append("voice", voice?.id);
      dispatch(cerateAvatar(formData));
    }
  };
  const setToDefault = () => {
    setAvatarForm(false);
    setAvatarImageURL();
    setVoice({});
    setName("");
    setDescription("");
    setAvatarImage();
    setError({});
  };
  //AvatarForm
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [audioIndex, setAudioIndex] = useState(1000);
  const [audioPlayer, setAudioPlayer] = useState(true);
  const [voiceList, setVoiceList] = useState();
  const { voices, getVoiceAuth } = useSelector((state) => state.voice || {});
  const audioPlay = (value) => {
    let x = document.getElementById(value.id);
    x.play();
  };
  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };
  useEffect(() => {
    if (getVoiceAuth) {
      setVoiceList(voices);
      dispatch(beforeVoice());
    }
  }, [getVoiceAuth, voices, dispatch]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div>
          <div className="web-bars" onClick={() => setShow(true)}>
            <img className="img-fluid" alt="" src={Images.bars} />
          </div>
          <div className={`sidebar ${show ? "show" : ""}`}>
            <InnerSidebar
              setShow={setShow}
              Images={Images}
              updateSideBarAuth={updateSideBarAuth}
              toggleAvatarHolder={toggleAvatarHolder}
            />
            <ChooseAvatar
              setActive={setActive}
              isActive={isActive}
              toggleAvatarHolder={toggleAvatarHolder}
              setImage={setImage}
              avatarList={avatarList}
              currentAvatar={currentAvatar}
              avatarSetter={avatarSetter}
            />
            <div
              className={`avatar-block pt-4 px-2 pb-2 ${
                avatarForm ? "show" : ""
              }`}
            >
              <div className="header-avatar-holder d-flex align-items-center mb-3 mb-md-5">
                <span
                  className="icon-arrow me-2 cursor-pointer"
                  onClick={() => {
                    setAvatarForm(false);
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} />
                </span>
                <span>Choose your avatar</span>
              </div>
              <form onSubmit={createAvatar} className="edit-avatar-form">
                <div>
                  <div className="avatar-holder d-flex justify-content-center align-items-center mb-2">
                    <img className="img-fluid" src={avatarImageURL} alt="" />
                  </div>
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInput"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setError({ ...error, name: "" });
                      }}
                    />
                    <label htmlFor="floatingInput">Name</label>
                    <span className={error?.name ? "text-danger" : ""}>
                      {error?.name}
                    </span>
                  </div>
                  <div className="form-floating">
                    <Dropdown
                      className="avatar-voice-dropdown"
                      show={isDropdownOpen}
                      onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
                    >
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {voice?.name || "Avatar Voice"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <strong className="group-heading d-block">Male</strong>
                        <ul className="list-unstyled mb-0">
                          {voiceList?.male_voice?.map((value) => {
                            return (
                              <li key={value.id}>
                                <div
                                  className="d-flex align-items-center"
                                  onClick={() => {
                                    setVoice(value);
                                    setError({ ...error, voice: "" });
                                    handleDropdownClose(); // Close the dropdown on click
                                  }}
                                >
                                  <span className="avatar-emoji">
                                    <img
                                      className="img-fluid"
                                      src={Images.iconMale}
                                      alt=""
                                    />
                                  </span>
                                  <span className="avatar-name flex-fill">
                                    {value?.name}
                                  </span>
                                </div>
                                {value.id === audioIndex || audioPlayer ? (
                                  <div className="icon-volume">
                                    <div className="audio-voice-holder">
                                      <audio
                                        id={value.id}
                                        onEnded={() => {
                                          setAudioPlayer(true);
                                        }}
                                      >
                                        <source
                                          src={value?.voice}
                                          type="audio/ogg"
                                        />
                                      </audio>
                                      <span
                                        className="audio-icon"
                                        onClick={() => {
                                          audioPlay(value);
                                          setAudioPlayer(false);
                                          setAudioIndex(value.id);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faVolumeHigh} />
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="icon-volume">
                                    <FontAwesomeIcon icon={faVolumeHigh} />
                                  </div>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                        <strong className="group-heading d-block">
                          Female
                        </strong>
                        <ul className="list-unstyled mb-0">
                          {voiceList?.female_voices?.map((value) => {
                            return (
                              <li key={value.id}>
                                <div
                                  className="d-flex align-items-center"
                                  onClick={() => {
                                    setVoice(value);
                                    setError({ ...error, voice: "" });
                                    handleDropdownClose(); // Close the dropdown on click
                                  }}
                                >
                                  <span className="avatar-emoji">
                                    <img
                                      className="img-fluid"
                                      src={Images.iconFemale}
                                      alt=""
                                    />
                                  </span>
                                  <span className="avatar-name flex-fill">
                                    {value?.name}
                                  </span>
                                </div>
                                {value.id === audioIndex || audioPlayer ? (
                                  <div className="icon-volume">
                                    <div className="audio-voice-holder">
                                      <audio
                                        id={value.id}
                                        onEnded={() => {
                                          setAudioPlayer(true);
                                        }}
                                      >
                                        <source
                                          src={value?.voice}
                                          type="audio/ogg"
                                        />
                                      </audio>
                                      <span
                                        className="audio-icon"
                                        onClick={() => {
                                          audioPlay(value);
                                          setAudioPlayer(false);
                                          setAudioIndex(value.id);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faVolumeHigh} />
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="icon-volume">
                                    <FontAwesomeIcon icon={faVolumeHigh} />
                                  </div>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                    <span className={error?.voice ? "text-danger" : ""}>
                      {error?.voice}
                    </span>
                  </div>
                  {description?.length < 1201 ? (
                    <div className="form-floating ">
                      <textarea
                        type="text"
                        className="form-control"
                        id="floatingInput"
                        placeholder="Description"
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                          setError({ ...error, description: "" });
                        }}
                      />

                      <label htmlFor="floatingInput">Description</label>
                      <span className="total-words">
                        {description.length} / 1200
                      </span>
                    </div>
                  ) : (
                    <div className="form-floating ">
                      <textarea
                        type="text"
                        className="form-control text-danger"
                        id="floatingInput"
                        placeholder="Description"
                        onChange={(e) => {
                          setDescription(e.target.value);
                          setError({ ...error, description: "" });
                        }}
                      />
                      <label htmlFor="floatingInput">Description</label>
                      <span className="total-words">
                        {description.length} / 1200
                      </span>
                    </div>
                  )}
                  <span className={error?.description ? "text-danger" : ""}>
                    {error?.description}
                  </span>
                </div>
                <button className="btn-solid" type="submit">
                  Create Avatar
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
