import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Images } from "../../../assets/asset";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  generateVideo,
  beforeFormData,
  setVideoError,
} from "../video-input-data/video-input-data.action";
import Loader from "../../loader/loader";
import "./header.css";
const Header = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const currentPathname = location.pathname;
  const videoForm = useSelector((state) => state.videoForm);
  const payment = useSelector((state) => state.payments?.payment);
  const generateVideoHandler = () => {
    if (payment?.remainingCredits === 0) {
      toast.error("Not enough credits");
    } else {
      let flag = true;
      if (!videoForm.isAudioFileFlag) {
        if (!videoForm.title) {
          flag = false;
          toast.error("Title missing");
          return;
        }
        if (!videoForm.voice) {
          flag = false;
          toast.error("Voice missing");
          return;
        }
        if (!videoForm.text) {
          flag = false;
          toast.error("Input message missing");
          return;
        } else if (videoForm.text.length < 5) {
          flag = false;
          toast.error("minimun text lenght should be 5 letters");
          return;
        }
      } else {
        if (!videoForm.title) {
          flag = false;
          toast.error("Title missing");
          return;
        }
        if (!videoForm.audio_file) {
          flag = false;
          return;
        }
      }
      if (flag) {
        let formData = new FormData();
        formData.append("title", videoForm.title);
        formData.append("presenter_id", videoForm?.image?.id);
        formData.append("presenter", videoForm?.image?.image);
        formData.append("audio_id", Number(videoForm?.voice?.id));
        formData.append("isAudioFileFlag", videoForm?.isAudioFileFlag);
        formData.append("language", "english");
        formData.append("text", videoForm?.text);
        formData.append("style", videoForm?.style);
        formData.append("creditPerTime", 15);
        formData.append("credits", payment?.remainingCredits);
        if (videoForm?.isAudioFileFlag) {
          formData.append("flag", videoForm?.isAudioInput);
          formData.append("audio_file", videoForm?.audio_file);
        }

        if (payment?.remainingCredits > 0) {
          setLoader(true);
          dispatch(generateVideo(formData));
        }
      }
    }
  };
  useEffect(() => {
    if (videoForm.resultVideoAuth || videoForm?.errorVideo) {
      setLoader(false);
      dispatch(setVideoError());
    }
  }, [videoForm.resultVideoAuth, videoForm?.errorVideo]);
  return (
    <>
      {loader ? (
        <Loader></Loader>
      ) : (
        <header className="header">
          <div className="heading">
            {currentPathname === "/studio" && <h2>Home</h2>}
            {currentPathname === "/studio/editor" && <h2>Create Video</h2>}
          </div>
          {currentPathname === "/studio" && (
            <div className="header-buttons">
              <Link to="/studio/editor" className="create-video-link">
                Create Video
              </Link>
            </div>
          )}
          {currentPathname === "/studio/editor" && (
            <div className="header-buttons">
              <button
                className="discard-button"
                onClick={() => {
                  dispatch(beforeFormData());
                }}
              >
                Discard Video
              </button>
              <button
                className="create-video-link ms-3"
                onClick={() => {
                  generateVideoHandler();
                }}
              >
                <img className="img-fluid" alt="" src={Images.camera} />
                <span className="ms-2">Generate Video</span>
              </button>
            </div>
          )}
          {currentPathname === "/account-settings" && (
            <div className="header-buttons">
              <Link to="/studio/editor" className="create-video-link">
                Create Video
              </Link>
            </div>
          )}
        </header>
      )}
    </>
  );
};

export default Header;
