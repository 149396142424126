import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ENV } from "../../config/config";
import { Images } from "../../assets/asset";
import "./conversational-ai.css";
const ConversationalAI = () => {
	return (
		<div className="conversational-ai d-id">
			<Container>
				<Row className="align-items-center">
					<Col lg={7}>
						<div className="video-holder">
							<img src={Images.conversationalAiImg} alt="" className="img-fluid"/>
						</div>
					</Col>
					<Col lg={5}>
						<div className="custom-card">
							<span>AI ‘Re-Facing</span>
							<h2 className="text-start">Human Conversations</h2>
							<p>{ENV?.appName} is an all-in-one web solution that uses real-time character animation and advanced text-to-speech technology. It offers an immersive and realistic conversational AI experience. Besides lifelike virtual avatars, it enables users to have direct conversations with ChatGPT.</p>
							<Link to="/login" className="filled-btn hvr-shutter-in-horizontal">Try It Now</Link>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
export default ConversationalAI;
