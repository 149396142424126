import {
  GET_VIDEOS,
  DELETE_VIDEO,
  GET_ERRORS,
  DOWNLOAD_VIDEO,
  COUNT_VIDEOS,
} from "../../redux/types";
import { ENV } from "../../config/config";
import { toast } from "react-toastify";
export const getVideos = (page, videosPerPage) => (dispatch) => {
  const url = `${ENV.backendURL}videos/get-videos?page=${page}&videosPerPage=${videosPerPage}`;
  fetch(url, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
      "x-access-token": localStorage.getItem("accessToken") || "",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: GET_VIDEOS,
          payload: data.data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data.message,
        });
      }
    })

    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};
export const deleteVideo = (id) => (dispatch, getState) => {
  const url = `${ENV.backendURL}videos/delete-video/${id}`;
  fetch(url, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
      "x-access-token": localStorage.getItem("accessToken") || "",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        toast.success("Video deleted successfully");
        const updatedVideos = getState().videos.videos.filter(
          (video) => video._id !== id
        );
        dispatch({
          type: DELETE_VIDEO,
          payload: updatedVideos,
        });
        dispatch(getVideos());
      } else {
        toast.error(data.message);
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};
export const downloadVideo = (id) => (dispatch) => {
  const url = `${ENV.backendURL}videos/download-video/${id}`;
  fetch(url, {
    method: "GET",
    headers: {
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
      "x-access-token": localStorage.getItem("accessToken") || "",
    },
  })
    .then(async (res) => {
      if (!res.ok) {
        throw new Error("Network response was not ok");
      }
      const contentDisposition = res.headers.get("Content-Disposition");
      const fileName = contentDisposition
        ? contentDisposition.split("filename=")[1]
        : "video.mp4";
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(url);
      dispatch({
        type: DOWNLOAD_VIDEO,
        payload: {},
      });
      toast.success("Video downloaded successfully");
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};

export const countVideos = () => (dispatch) => {
  const url = `${ENV.backendURL}videos/count-videos`;
  fetch(url, {
    method: "GET",
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: COUNT_VIDEOS,
          payload: data.data,
        });
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};
