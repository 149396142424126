import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ENV } from "../../config/config";
import "./join-community.css";
const JoinCommunity = () => {
	return (
		<div className="join-community d-id">
			<Container>
				<div className="d-flex justify-content-center flex-column align-items-center">
					<span> Connect with</span>
					<h2> {ENV?.appName} Community</h2>
					<p>Join our consistently growing {ENV?.appName} community and share your video creations with stunning digital avatars, ask questions, share your knowledge, give feedback, and stay updated about product updates.</p>
					<Link to="/login" className="filled-btn hvr-shutter-in-horizontal-white join-community-btn " > Join Now </Link>
				</div>
			</Container>
		</div>
	);
};
export default JoinCommunity;
