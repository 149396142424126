import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPlus,
  faTimes,
  fatimes,
} from "@fortawesome/free-solid-svg-icons";
import { deleteAvatar } from "../avatar/avatar.action";
import { useDispatch } from "react-redux";

const ChooseAvatar = ({
  setActive,
  isActive,
  toggleAvatarHolder,
  setImage,
  avatarList,
  currentAvatar,
  avatarSetter,
}) => {
  const dispatch = useDispatch();

  return (
    <div className={`avatar-block pt-4 px-2 pb-2 ${isActive ? "show" : ""}`}>
      <div className="header-avatar-holder d-flex align-items-center mb-3 mb-md-5">
        <span
          className="icon-arrow me-2 cursor-pointer"
          onClick={toggleAvatarHolder}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </span>
        <span>Choose your avatar</span>
      </div>
      <ul className="avatars-holder list-unstyled d-flex flex-wrap">
        <li>
          <div className="avatar-uploader">
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              className="cursor-pointer"
              onChange={(e) => {
                setImage(e);
              }}
            />
            <span className="upload-text w-100 h-100 d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faPlus} />
            </span>
          </div>
        </li>
        {avatarList?.map((value, index) => {
          if (value?._id === currentAvatar?._id)
            return (
              <li key={index} className="avatars-holder-li">
                {value?.isDefault ? (
                  <span />
                ) : (
                  <button
                    className="x-mark-btn"
                    onClick={() => {
                      dispatch(deleteAvatar(value._id));
                      setActive(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                )}
                <button
                  className="selected"
                  onClick={() => {
                    avatarSetter(value);
                  }}
                >
                  <div className="avatar-holder d-flex justify-content-center align-items-center mb-2">
                    <img className="img-fluid" src={value?.image} alt="" />
                  </div>
                  <span className="avatar-name d-block">
                    <span className="text-avatar d-flex justify-content-center">
                      <span>{value?.name}</span>
                    </span>
                  </span>
                </button>
              </li>
            );
          else
            return (
              <li key={index} className="avatars-holder-li">
                {value?.isDefault ? (
                  <span />
                ) : (
                  <button
                    className="x-mark-btn"
                    onClick={() => {
                      dispatch(deleteAvatar(value._id));
                      setActive(false);
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                )}
                <button
                  onClick={() => {
                    avatarSetter(value);
                  }}
                >
                  <div className="avatar-holder d-flex justify-content-center align-items-center mb-2">
                    <img className="img-fluid" src={value?.image} alt="" />
                  </div>
                  <span className="avatar-name d-block">
                    <span className="text-avatar d-flex justify-content-center">
                      <span>{value?.name}</span>
                    </span>
                  </span>
                </button>
              </li>
            );
        })}
      </ul>
    </div>
  );
};
export default ChooseAvatar;
