import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Images } from "../../assets/asset";
import "./digital-creations.css";
const DigitalCreation = () => {
	return (
		<div className="digital-creation d-id">
			<Container>
				<Row className="align-items-center">
					<Col xl={6} lg={7} className="d-flex justify-content-center">
						<div className="video-holder">
							<div className="robort-icon-holder d-flex justify-content-center align-items-center">
								<img className="img-fluid" src={Images.RobotIcon} alt="robot" />
							</div>
							<div className="video-icon-holder d-flex justify-content-center align-items-center">
								<img className="img-fluid" src={Images.VideoIcon} alt="robot" />
							</div>
							<img src={Images.DigitalCreationImg} alt="" className="img-fluid"/>
						</div>
					</Col>
					<Col xl={6} lg={5}>
						<div className="digital-creation-content">
							<span>Content Creation for </span>
							<h2 className="digital-creation-heading text-start">People’s Ideas</h2>
							<p>Leverage the power of generative AI tools to effortlessly produce high-quality virtual doppelgangers within seconds and use them as presenters in your explainer, how-to, training, news, personalized sales, and marketing videos.</p>
							<p>Empowered by the combination of GPT-3 and Stable Diffusion, our self-service AI-Avatar Lab helps generate interactive stories and prompts to represent you, your brand, and your services or products. Convert your concepts into fast, personalized videos with high-definition clarity. You do not need any technical expertise for video production when you have AR-AI at your side.</p>
							<Link to="/login" className="filled-btn hvr-shutter-in-horizontal">Try It Now</Link>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
export default DigitalCreation;
