import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

import { useDispatch } from "react-redux";
import { getPayment } from "../../components/stripe-payment/stripe-payment.action";

const Layout4 = (props) => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken") || "";
  useEffect(() => {
    if (accessToken) dispatch(getPayment());
  }, [accessToken , dispatch]);
  return (
    <div className="landing-page">
      <Header />
      <div className="d-flex pages-main-wrapper">
        <Container>{props.children}</Container>
      </div>
      <Footer />
      </div>
  );
};

export default Layout4;
