import { CREATE_CONTACT } from "../../redux/types";

const initialState = {
  contact: null,
  createContactAuth: false,
};

const contactUsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CONTACT:
      return {
        ...state,
        contact: action.payload,
        createContactAuth: true,
      };

    default:
      return {
        ...state,
      };
  }
};
export default contactUsReducer;
