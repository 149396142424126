import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import { Images } from "../../assets/asset";
import { ENV } from "../../config/config";
import "./header.css";
export default function Header() {
  const loginUser = localStorage.getItem("accessToken");
  return (
    <header id="header">
      <Container>
        <div className="d-flex justify-content-center align-items-center top-quote align-items-center">
          <span>
            Experience The Future Of Conversational AI With {ENV?.appName}.
          </span>
        </div>
        <div className="">
          <Navbar
            expand="xl"
            className="justify-content-between p-0 align-items-center"
          >
            <strong className="home-page-logo d-inline-block">
              <Link className="navbar-brand d-inline-block align-top" to="/">
                <img className="logo-img" src={Images.companyLogo} alt="logo" />
              </Link>
            </strong>
            <div className="d-flex align-items-center nav-content">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav" className="">
                <div>
                  <Nav className="mr-auto w-100">
                    <Link className="nav-link" to="/studio">
                      Studio
                    </Link>
                    <Link className="nav-link" to="/ar-ai">
                      Chat
                    </Link>
                    <Link className="nav-link" to="/studio/pricing">
                      Pricing
                    </Link>
                    <Link className="nav-link" to="/studio/faqs">
                      Faqs
                    </Link>
                    <Link className="nav-link" to="/studio/contact">
                      Contact
                    </Link>
                  </Nav>
                </div>
              </Navbar.Collapse>
              <div className="d-flex align-items-center">
                {!loginUser ? (
                  <>
                    <div className="user-holder me-2 mb-sm-0">
                      <Link to="/login" className="header-login-icon">
                        <FontAwesomeIcon icon={faUser} />
                      </Link>
                    </div>
                    <Link
                      to="/signup"
                      className="filled-btn mb-sm-0 hvr-shutter-in-horizontal"
                    >
                      Register
                    </Link>
                  </>
                ) : (
                  <Link
                    to="/studio"
                    className="filled-btn mb-sm-0 hvr-shutter-in-horizontal d-flex justify-content-center"
                  >
                    <span>Start Free Trial</span>
                  </Link>
                )}
              </div>
            </div>
          </Navbar>
        </div>
      </Container>
    </header>
  );
}
