import { toast } from "react-toastify";
import { emptyError } from "../../redux/shared/error/error.action";
import { ENV } from "../../config/config";
import {
  CREATE_PAYMENT,
  BEFORE_PAYMENT,
  GET_ERRORS,
  UPDATE_SIDEBAR,
  CANCEL_SUBSCRIPTION,
  SET_CREATE_PAYMENT,
  GET_PAYMENT,
} from "../../redux/types";
export const beforePayment = () => {
  return {
    type: BEFORE_PAYMENT,
  };
};
export const setCreatePayment = () => {
  return {
    type: SET_CREATE_PAYMENT,
  };
};
export const updateSideBar = (body) => (dispatch) => {
  dispatch({
    type: UPDATE_SIDEBAR,
    payload: body,
  });
};
export const createPayment =
  (body = null) =>
  (dispatch) => {
    dispatch(emptyError());
    let url = `${ENV.backendURL}payment/create-payment`;
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: ENV.Authorization,
        "x-auth-token": ENV.x_auth_token,
        "x-access-token": localStorage.getItem("accessToken") || "",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          dispatch({
            type: CREATE_PAYMENT,
            payload: data.packages,
          });
        } else {
          toast.error(data.message);
          dispatch({ type: GET_ERRORS, payload: data });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { data } = error.response;
          if (data.message) toast.error(data.message);
        }
        dispatch({
          type: GET_ERRORS,
          payload: error,
        });
      });
  };
export const cancelSubscription = () => (dispatch) => {
  dispatch(emptyError());
  let url = `${ENV.backendURL}payment/cancel-subscription`;
  fetch(url, {
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
      "x-access-token": localStorage.getItem("accessToken") || "",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      toast.success(data.message);
      window.location.href = "/studio/pricing";

      if (data.success) {
        dispatch({
          type: CANCEL_SUBSCRIPTION,
        });
      } else {
        toast.error(data.message);
        dispatch({ type: GET_ERRORS, payload: data });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};
export const getPayment = () => (dispatch) => {
  dispatch(emptyError());
  let url = `${ENV.backendURL}payment/get-payment`;
  fetch(url, {
    method: "GET",
    headers: {
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
      "x-access-token": localStorage.getItem("accessToken") || "",
    },
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        dispatch({
          type: GET_PAYMENT,
          payload: data.data,
        });
      } else {
        toast.error(data.message);
        dispatch({ type: GET_ERRORS, payload: data });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};
