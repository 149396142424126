import React from "react";
import { Container } from "react-bootstrap";  
import Slider from "react-slick";
import { Images } from "../../assets/asset";
import "./our-customers.css";
const OurCustomers = () => {
  let settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    arrows: true,
    dots: false,
    speed: 300,
    centerPadding: "0px",
    infinite: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          centerMode: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2.25,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 578,
        settings: {
          slidesToShow: 2.25,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="our-customer d-id">
      <Container>
        <span>What Our</span>
        <h2 className="our-customer-heading text-start">Customer Says</h2>
        <Slider {...settings}>
          <div className="px-2">
            <div className="review-card">
              <div className="card-body">
                <p>
                  "Cras gravida bibendum dolor eu varius. Morbi fermentum velit
                  nisl, eget vehicula lorem sodales eget. Donec quis volutpat
                  orci. Sed ipsum felis, tristique id egestas et, convallis ac
                  velit. In consequat dolor libero"
                </p>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="user-dp me-3">
                    <img src={Images.UserDp} alt="user" />
                  </div>
                  <div>
                    <strong className="d-block">Amanda Hudson</strong>
                    <span className="d-block">Europe</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="px-2">
            <div className="review-card">
              <div className="card-body">
                <p>
                  "Cras gravida bibendum dolor eu varius. Morbi fermentum velit
                  nisl, eget vehicula lorem sodales eget. Donec quis volutpat
                  orci. Sed ipsum felis, tristique id egestas et, convallis ac
                  velit. In consequat dolor libero"
                </p>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="user-dp me-3">
                    <img src={Images.UserDp} alt="user" />
                  </div>
                  <div>
                    <strong className="d-block">Amanda Hudson</strong>
                    <span className="d-block">Europe</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-2">
            <div className="review-card">
              <div className="card-body">
                <p>
                  "Cras gravida bibendum dolor eu varius. Morbi fermentum velit
                  nisl, eget vehicula lorem sodales eget. Donec quis volutpat
                  orci. Sed ipsum felis, tristique id egestas et, convallis ac
                  velit. In consequat dolor libero"
                </p>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="user-dp me-3">
                    <img src={Images.UserDp} alt="user" />
                  </div>
                  <div>
                    <strong className="d-block">Amanda Hudson</strong>
                    <span className="d-block">Europe</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-2">
            <div className="review-card">
              <div className="card-body">
                <p>
                  "Cras gravida bibendum dolor eu varius. Morbi fermentum velit
                  nisl, eget vehicula lorem sodales eget. Donec quis volutpat
                  orci. Sed ipsum felis, tristique id egestas et, convallis ac
                  velit. In consequat dolor libero"
                </p>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="user-dp me-3">
                    <img src={Images.UserDp} alt="user" />
                  </div>
                  <div>
                    <strong className="d-block">Amanda Hudson</strong>
                    <span className="d-block">Europe</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </Container>
    </div>
  );
};
export default OurCustomers;
