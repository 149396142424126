import {
  GET_PRESENTERS,
  BEFORE_PRESETNTER,
  CREATE_PRESENTER,
  SELECT_PRESENTER,
  DELETE_PRESENTER,
} from "../../redux/types";
const initialState = {
  presenter: null,
  presenters: null,
  selectedPresenter: null,
  selectedPresenterAuth: false,
  getPresenterAuth: false,
  createPresenterAuth: false,
  deletePresenterAuth: false,
};
const presenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRESENTERS:
      return {
        ...state,
        presenters: action.payload,
        getPresenterAuth: true,
      };
    case CREATE_PRESENTER:
      return {
        ...state,
        presenter: action.payload,
        createPresenterAuth: true,
      };
    case SELECT_PRESENTER:
      return {
        ...state,
        selectedPresenter: action.payload,
        selectedPresenterAuth: true,
      };
    case BEFORE_PRESETNTER:
      return {
        ...state,
        presenter: null,
        presenters: null,
        getPresenterAuth: false,
        createPresenterAuth: false,
        deletePresenterAuth: false,
      };
    case DELETE_PRESENTER:
      return {
        ...state,
        presenter: action.payload,
        deletePresenterAuth: true,
      };
    default:
      return {
        ...state,
      };
  }
}
export default presenterReducer