// reducer.js
import { DELETE_VIDEO, DOWNLOAD_VIDEO, GET_VIDEOS, COUNT_VIDEOS } from "../../redux/types";

// ... Your existing reducer ...

const initialState = {
  videos: null,
  getVideoAuth: false,
  videoCount: 0, // Add a new state property for video count
};

const videoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VIDEOS:
      return {
        ...state,
        videos: action.payload,
        getVideoAuth: true,
      };
    case DELETE_VIDEO:
      return {
        ...state,
        videos: action.payload,
      };
    case COUNT_VIDEOS:
      return {
        ...state,
        videoCount: action.payload, 
      };
    case DOWNLOAD_VIDEO:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default videoReducer;
