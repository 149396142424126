import {
  SET_TITLE,
  SET_IMAGE,
  SET_TEXT,
  SET_VOICE,
  SET_LANGUAGE,
  SET_STYLE,
  SET_AUDIO_FILE,
  SUBMIT_FORM_DATA,
  CREATE_VIDEO,
  VIDEO_ERROR,
  BEFORE_FORM_DATA,
  DISCARD_FORM_DATA,
  SET_VIDEO_ERROR,
  SET_AUDIO_INPUT,
} from "../../../redux/types";
const initialState = {
  title: "Untitled video",
  image: null,
  text: "",
  voice: null,
  language: null,
  style: null,
  audio_file: null,
  imageUrl: null,
  formSubmitAuth: false,
  discardVideoAuth: false,
  resultVideo: null,
  resultVideoAuth: false,
  isAudioFileFlag: false,
  isAudioInput: false,
  errorVideo: false,
};
const videoInputReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case SET_IMAGE:
      return {
        ...state,
        image: action.payload,
      };
    case SET_TEXT:
      return {
        ...state,
        text: action.payload,
      };
    case SET_VOICE:
      return {
        ...state,
        voice: action.payload,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case SET_STYLE:
      return {
        ...state,
        style: action.payload,
      };
    case SET_AUDIO_FILE:
      return {
        ...state,
        audio_file: action.payload,
        isAudioFileFlag: true,
      };
    case SET_AUDIO_INPUT:
      return {
        ...state,
        isAudioFileFlag: action.payload,
      };
    case SUBMIT_FORM_DATA:
      return {
        ...state,
        formSubmitAuth: true,
      };
    case DISCARD_FORM_DATA:
      return {
        ...state,
        discardVideoAuth: true,
      };
    case CREATE_VIDEO:
      return {
        ...state,
        resultVideo: action.payload,
        resultVideoAuth: true,
      };
    case VIDEO_ERROR:
      return {
        ...state,
        errorVideo: true,
      };
    case SET_VIDEO_ERROR:
      return {
        ...state,
        title: "Untitled video",
        text: "",
        voice: null,
        language: null,
        style: null,
        imageUrl: null,
        audio_file: null,
        formSubmitAuth: false,
        discardVideoAuth: false,
        resultVideoAuth: false,
        isAudioFileFlag: false,
        isAudioInput: false,
        errorVideo: false,
      };
    case BEFORE_FORM_DATA:
      return {
        ...state,
        title: "Untitled video",
        text: "",
        voice: null,
        language: null,
        style: null,
        imageUrl: null,
        audio_file: null,
        formSubmitAuth: false,
        discardVideoAuth: false,
        resultVideoAuth: false,
        isAudioFileFlag: false,
        isAudioInput: false,
        errorVideo: false,
      };
    default:
      return {
        ...state,
      };
  }
};
export default videoInputReducer;
