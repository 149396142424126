import { BEFORE_GPT, GET_GPT } from "../../../redux/types";
const initialState = {
  askGpt: null,
  getGptAuth: false,
};
const gptReducer = (state = initialState, action) => {
  switch (action.type) {
    case BEFORE_GPT:
      return {
        askGpt: null,
        getGptAuth: false,
      };
    case GET_GPT:
      return {
        askGpt: action.payload,
        getGptAuth: true,
      };
    default:
      return {
        ...state,
      };
  }
}
export default gptReducer
