import React from "react";
import { Container } from "react-bootstrap";
import { Images } from "../../assets/asset";
import "./award-winning.css";

const AwardWinning = () => {
  return (
    <div className="award-winning d-id">
      <Container>
        <div className="heading d-flex justify-content-center flex-column">
          <span className="text-center mb-3">Acclaimed Excellence.</span>
          <div className="heading d-flex justify-content-center">
            <h2>Transform Images Into AI Video Narrators At Scale.</h2>
          </div>
        </div>
        <div className="heading d-flex justify-content-center flex-wrap">
          <div className="brand-holder me-2 mb-2 d-flex justify-content-center align-items-center">
            <img className="img-fluid" src={Images.Brand1} alt="brand" />
          </div>
          <div className="brand-holder me-2 mb-2 d-flex justify-content-center align-items-center">
            <img className="img-fluid" src={Images.Brand2} alt="brand" />
          </div>
          <div className="brand-holder me-2 mb-2 d-flex justify-content-center align-items-center">
            <img className="img-fluid" src={Images.Brand3} alt="brand" />
          </div>
          <div className="brand-holder me-2 mb-2 d-flex justify-content-center align-items-center">
            <img className="img-fluid" src={Images.Brand4} alt="brand" />
          </div>
          <div className="brand-holder me-2 mb-2 d-flex justify-content-center align-items-center">
            <img className="img-fluid" src={Images.Brand5} alt="brand" />
          </div>
        </div>
      </Container>
    </div>
  );
};
export default AwardWinning;
