import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import CountUp from 'react-countup';
import "./shared-stories.css";
import { useSelector , useDispatch} from "react-redux";
import { countVideos } from "../video/video.action";
const SharedStories = () => {
  const videoCount = useSelector((state)=>state.videos?.videoCount)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(countVideos());
  }, [dispatch]);

  return (
    <div className="shared-stories d-id">
      <Container>
        <div className="heading d-flex justify-content-center">
          <h2>
            <strong className="d-block">Share your story using</strong>
            Creative Reality<span>TM</span> Studio
          </h2>
        </div>
        <div className="d-flex justify-content-center mb-2 shared-stories-para">
          <p>
            Transform photos into AI video presenters at scale. Produce
            AI-powered, cost-effective videos for training materials, internal
            communications, marketing and more, at the touch of a button.
          </p>
        </div>
        <div className="d-flex justify-content-center align-items-center mb-2 flex-wrap">
          <div className="count-holder d-flex justify-content-center align-items-center">
            {/* <strong className="count-value">{videoCount}</strong> */}
			<CountUp delay={1} end={videoCount}  enableScrollSpy={true} className="count-value" />
          </div>
          <div className="count-holder d-flex justify-content-center align-items-center">
		  	<CountUp delay={1} end={videoCount}  enableScrollSpy={true} className="count-value" />
          </div>
          <div className="count-holder d-flex justify-content-center align-items-center">
		  	<CountUp delay={1} end={videoCount}  enableScrollSpy={true} className="count-value" />
          </div>
        </div>
        <span className="d-flex justify-content-center align-items-center created-since">
          Videos Created Since <span className="year ms-1">2023</span>
        </span>
      </Container>
    </div>
  );
};
export default SharedStories;
