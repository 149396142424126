import {
  GET_VOIECS,
  GET_VOICE,
  BEFORE_VOICE,
  GET_VOICE_DROPDOWN,
  LISTEN_VOICE,
} from "../../redux/types";
const initialState = {
  voice: null,
  voices: null,
  getVoiceAuth: false,
  voiceDropdown: null,
  voiceDropdownAuth: false,
  listenVoice: null,
  listenVoiceAuth: false,
};
const voiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VOICE:
      return {
        ...state,
        voice: action.paylaod,
        getVoiceAuth: true,
      };
    case GET_VOIECS:
      return {
        ...state,
        voices: action.payload,
        getVoiceAuth: true,
      };
    case GET_VOICE_DROPDOWN:
      return {
        ...state,
        voiceDropdown: action.payload,
        voiceDropdownAuth: true,
      };
    case LISTEN_VOICE:
      return {
        ...state,
        listenVoice: action.payload,
        listenVoiceAuth: true,
      };
    case BEFORE_VOICE:
      return {
        ...state,
        voice: null,
        voices: null,
        getVoiceAuth: false,
        voiceDropdown: null,
        voiceDropdownAuth: false,
        listenVoice: null,
        listenVoiceAuth: false,
      };
    default:
      return {
        ...state,
      };
  }
};
export default voiceReducer;
