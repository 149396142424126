import {
  SET_TITLE,
  SET_IMAGE,
  SET_TEXT,
  SET_VOICE,
  SET_LANGUAGE,
  SET_STYLE,
  SET_AUDIO_FILE,
  SET_AUDIO_INPUT,
  BEFORE_FORM_DATA,
  DISCARD_FORM_DATA,
  SET_VIDEO_ERROR,
  CREATE_VIDEO,
  GET_ERRORS,
  VIDEO_ERROR,
} from "../../../redux/types";
import { ENV } from "../../../config/config";
import { toast } from "react-toastify";
export const beforeFormData = () => {
  return {
    type: BEFORE_FORM_DATA,
  };
};
export const generateVideo = (body) => (dispatch) => {
  const url = `${ENV.backendURL}videos/generate-video`;
  fetch(url, {
    method: "POST",
    headers: {
      Authorization: ENV.Authorization,
      "x-auth-token": ENV.x_auth_token,
      "x-access-token": localStorage.getItem("accessToken") || "",
    },
    body: body,
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success) {
        toast.success("Video Generated Successfully");
        dispatch({
          type: CREATE_VIDEO,
          payload: data.data,
        });
      } else if (!data.success && data.status === 200) {
        toast.error(data.message);
        dispatch({ type: VIDEO_ERROR });
      } else {
        toast.error(data.message);
        dispatch({ type: VIDEO_ERROR });
        dispatch({
          type: GET_ERRORS,
          payload: data,
        });
      }
    })
    .catch((error) => {
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.message) toast.error(data.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: error,
      });
    });
};
export const discardFormData = () => {
  return {
    type: DISCARD_FORM_DATA,
  };
};
export const setVideoError = () => {
  return {
    type: SET_VIDEO_ERROR,
  };
};
export const setAudioType = (body) => (dispatch) => {
  dispatch({
    type: SET_AUDIO_INPUT,
    payload: body,
  });
};
export const setTitle = (body) => (dispatch) => {
  dispatch({
    type: SET_TITLE,
    payload: body,
  });
};
export const setImage = (body) => (dispatch) => {
  dispatch({
    type: SET_IMAGE,
    payload: body,
  });
};
export const setText = (body) => (dispatch) => {
  dispatch({
    type: SET_TEXT,
    payload: body,
  });
};
export const setVoice = (body) => (dispatch) => {
  dispatch({
    type: SET_VOICE,
    payload: body,
  });
};
export const setLanguage = (body) => (dispatch) => {
  dispatch({
    type: SET_LANGUAGE,
    payload: body,
  });
};
export const setStyle = (body) => (dispatch) => {
  dispatch({
    type: SET_STYLE,
    payload: body,
  });
};
export const setAudioFile = (body) => (dispatch) => {
  dispatch({
    type: SET_AUDIO_FILE,
    payload: body,
  });
};
